import React from 'react'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'

import {
  Settings,
  DollarSign,
  Award,
  Smartphone,
  Mail,
  Printer,
  MapPin,
} from 'react-feather'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const ServicesPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  section2,
  section3,
  body,
}) => (
  <main className="ServicesPage">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transform={{ duration: 1 }}
    >
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <section className="section">
        <div className="container col-lg-12">
          <div className="row">
            <section className="section">
              <div className="container row">
                <div className="col-lg-6 taLeft">
                  <div className="py-4">
                    <div className="icon">
                      <Settings size="50" color="#FFF" />
                    </div>
                    <h3 className="title">PRODUCT DEVELOPMENT</h3>
                  </div>
                </div>
                <div className="col-lg-6 taLeft">
                  <Content source={section1} />
                </div>
              </div>
            </section>

            <section className="section">
              <div className="container row">
                <div className="col-lg-6 taLeft">
                  <div className="py-4">
                    <div className="icon">
                      <DollarSign size="50" color="#FFF" />
                    </div>
                    <h3 className="title">SALES &amp; MARKETING</h3>
                  </div>
                </div>
                <div className="col-lg-6 taLeft">
                  <Content source={section2} />
                </div>
              </div>
            </section>

            <section className="section">
              <div className="container row">
                <div className="col-lg-6 taLeft">
                  <div className="py-4">
                    <div className="icon">
                      <Award size="50" color="#FFF" />
                    </div>
                    <h3 className="title">MANAGEMENT &amp;&nbsp;SUPPORT</h3>
                  </div>
                </div>
                <div className="col-lg-6 taLeft">
                  <Content source={section3} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section className="section Contact--Section">
        <div className="container mx-auto">
          <div className="row Contact--Footer">
            <div className="col-lg-6">
              <div className="container p-4">
                <h3 className="title">Let's Talk</h3>
                <p>We look forward to helping your club business grow!</p>
                <FormSimpleAjax name="Simple Form Ajax" />
              </div>
            </div>
            <div className="col-lg-6 Contact--Details taLeft my-auto p-4">
              <ul>
                <li className="row">
                  <div className="col-1">
                    <MapPin />
                  </div>
                  <div className="col-8">
                    <p>
                      7851 Mission Center Court, Suite 115 San Diego,
                      CA&nbsp;92108
                    </p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-1">
                    <Mail />
                  </div>
                  <div className="col-8">
                    <p>info@massmarketingservices.com</p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-1">
                    <Smartphone />
                  </div>
                  <div className="col-8">
                    <p>619.297.0203</p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-1">
                    <Printer />
                  </div>
                  <div className="col-8">
                    <p>619.297.0449</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  </main>
)

const ServicesPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ServicesPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default ServicesPage

export const pageQuery = graphql`
  query ServicesPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        section1
        section2
        section3
      }
    }
  }
`
